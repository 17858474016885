<template>
  <div class="flex flex-wrap w-full">
    <div>
      <base-dialog
        :show="!!error"
        :errorIcon="!!error"
        title="Oops. Something went wrong"
        @close="handleError"
      >
        <p>{{ error }}</p>
      </base-dialog>
    </div>

    <div class="w-2/5 shadow-2xl">
      <img
        class="hidden object-cover w-full h-screen md:block"
        src="@/assets/images/authImage.jpg"
      />
    </div>
    <div class="flex flex-col w-full md:w-3/5">
      <div class="flex justify-center pt-12 md:justify-end md:pr-12 md:-mb-24">
        <p class="p-4 text-xl font-bold text-grey">Login</p>
      </div>
      <div
        class="
          flex flex-col
          justify-center
          px-4
          pt-4
          my-auto
          md:justify-start md:pt-0 md:px-24
          lg:px-44
        "
      >
        <p class="text-3xl text-grey font-bold">
          eHarleyStreet Strategic Management System
        </p>
        <p class="font-normal text-grey pt-3">
          Please note: <br />This portal is for members of eHarleyStreet only.
        </p>
        <!-- <form class="flex flex-col pt-3 md:pt-8"> -->
        <form
          @submit.prevent="submitForm"
          class="form-login flex flex-col max-w-md pt-3 md:pt-8"
        >
          <div
            class="mb-1 shadow-2xl"
            :class="{ 'border border-red': !email.isValid }"
          >
            <input
              id="login_email"
              name="email"
              type="email"
              class="w-full rounded-sm py-5 pr-0 pl-2 focus:outline-none"
              placeholder="Email"
              required
              v-model.trim="email.val"
              @blur="clearInvalid('email')"
              :disabled="!!error"
            />
          </div>

          <div
            class="mb-1 shadow-2xl"
            :class="{ 'border border-red': !password.isValid }"
          >
            <input
              id="login-password"
              name="password"
              type="password"
              class="w-full rounded-sm py-5 pr-0 pl-2 focus:outline-none"
              placeholder="Password"
              required
              v-model.trim="password.val"
              @blur="clearInvalid('password')"
              :disabled="!!error"
            />
          </div>

          <div class="mb-1">
            <div class="pt-4 text-grey">
              <span class="float-right no-underline hover:text-darkGrey">
                <router-link :to="{ name: 'gu-forgotPassword' }">
                  <i>Forgot Password</i></router-link
                >
              </span>
            </div>
          </div>
          <div v-if="isLoading">
            <heart-rate fast="true"></heart-rate>
          </div>
          <template v-else>
            <base-button :disabled="!!error">LOG IN</base-button>
          </template>
        </form>

        <div class="pt-12 pb-12 text-center"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: {
        val: "",
        isValid: true,
      },
      password: {
        val: "",
        isValid: true,
      },
      formIsValid: true,
      isLoading: false,
      error: null,
    };
  },
  methods: {
    clearInvalid(input) {
      this[input].isValid = true;
    },
    formSubmitValidate() {
      this.formIsValid = true;
      if (this.email.val == "") {
        this.email.isValid = false;
        this.formIsValid = false;
      }
      if (this.password.val == "") {
        this.password.isValid = false;
        this.formIsValid = false;
      }
    },
    async submitForm() {
      this.formSubmitValidate();

      if (!this.formIsValid) {
        return;
      }

      this.isLoading = true;
      const actionPayload = {
        email: this.email.val,
        password: this.password.val,
      };

      try {
        await this.$store.dispatch("auth/login", actionPayload);
        this.handleRedirect();
      } catch (err) {
        if (err.success == false) {
          this.formIsValid = false;
          this.email.isValid = false;
          this.password.isValid = false;
        } else this.error = err.message;
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      }

      this.isLoading = false;
    },
    handleRedirect() {
      const { redirect } = this.$route.query;
      if (redirect)
        return this.$router.push(redirect).catch(() => {
          this.$router.push({ name: "homepage" });
        });

      this.$router.push({ name: "homepage" });
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
.form-login input[type="email"]:focus,
.form-login input[type="email"]:hover,
.form-login input[type="password"]:focus,
.form-login input[type="password"]:hover {
  background-color: #ffffff;
  border-left: solid 3px #00a499;
  filter: drop-shadow(2px 5px 5px rgb(219, 219, 219));
}
</style>
